/* eslint-disable no-inline-styles/no-inline-styles */
import React from 'react';

import dynamic from 'next/dynamic';

import { layoutIdAtom } from '@/core/features/app/app-atoms';
import { useAtomValue, useSetAtom } from '@/core/features/store/atom-store';
import { useFeatureFlagStore } from '@/core/features/store/feature-flags-store';
import { accommodationFiltersAtom } from '@/features/accommodation-filter/accommodation-filter-state';
import GoogleMapWrapper from '@/features/map/google-map/google-map-wrapper';
import MapAccommodationFilterDesktop from '@/features/map/map-components/map-filter/map-accommodation-filter-desktop';
import MapFilterDesktop from '@/features/map/map-components/map-filter/map-filter-desktop';
import MapLayerSwitchDesktop from '@/features/map/map-components/map-layer-switch/map-layer-switch-desktop';
import MapLoadMoreButton from '@/features/map/map-components/map-load-more-button/map-load-more-button';
import MapMarkerHandler from '@/features/map/map-components/map-markers/map-marker-handler/map-marker-handler';
import MapVerticalToggle from '@/features/map/map-components/map-vertical-toggle/map-vertical-toggle';
import MapZoomControl from '@/features/map/map-components/map-zoom-control/map-zoom-control';
import useMapComponent from '@/features/map/map-container/use-map-component';
import useMapSplitscreenSize from '@/features/map/map-container/use-map-splitscreen-size';

const MapDebugInspector = dynamic(
    () => import(/* webpackChunkName: "MapDebugInspector" */ '@/features/map/map-container/map-debug-inspector'),
);

export default function MapComponentDesktop({ componentId }: { componentId: string }) {
    const {
        accommodationFilters,
        filterGroups,
        icons,
        isLoadMoreButtonEnabled,
        loadMoreButton,
        mapType,
        onClusterClick,
        onLoadMoreClick,
        onPoiClick,
        onZoomControlChange,
        pins,
        updateMapType,
        verticalToggleIconUrl,
        verticalToggleOptions,
    } = useMapComponent();

    const [isMapDebugUIEnabled] = useFeatureFlagStore('isMapDebugUIEnabled');

    const mapScreenSizeRef = React.useRef<HTMLDivElement>(null);
    useMapSplitscreenSize(mapScreenSizeRef);

    const [isVerticalToggleOpen, setIsVerticalToggleOpen] = React.useState(false);

    const layoutId = useAtomValue(layoutIdAtom);
    const isMapSearchLayout = layoutId?.includes('Map_Search');

    const handleToggle = React.useCallback(() => {
        setIsVerticalToggleOpen((prevState) => !prevState);
    }, []);

    const setAccommodationFilters = useSetAtom(accommodationFiltersAtom);

    React.useEffect(() => {
        if (!accommodationFilters) {
            return;
        }
        setAccommodationFilters(accommodationFilters);
    }, [accommodationFilters, setAccommodationFilters]);

    return (
        <>
            {accommodationFilters && (
                <MapAccommodationFilterDesktop
                    accommodationFilters={accommodationFilters}
                    componentId={componentId}
                />
            )}
            {filterGroups && (
                <MapFilterDesktop
                    componentId={componentId}
                    filterGroups={filterGroups}
                />
            )}
            <div
                className={'width-100 height-100 relative overflow-hidden'}
                ref={mapScreenSizeRef}
            >
                <GoogleMapWrapper mapType={mapType}>
                    {pins && (
                        <MapMarkerHandler
                            icons={icons}
                            isDesktopView={true}
                            onClusterClick={onClusterClick}
                            onPoiClick={onPoiClick}
                            pins={pins}
                        />
                    )}
                </GoogleMapWrapper>
                {isMapDebugUIEnabled && <MapDebugInspector />}
                {isLoadMoreButtonEnabled && (
                    <MapLoadMoreButton
                        loadMoreButton={loadMoreButton ?? null}
                        onLoadMore={onLoadMoreClick}
                    />
                )}

                <div
                    className={'flex-center-vertical absolute gap-15'}
                    style={{
                        right: 12,
                        top: isMapSearchLayout ? 20 : 75,
                    }}
                >
                    <MapZoomControl onZoomControlChange={onZoomControlChange} />
                    <MapLayerSwitchDesktop
                        activeMapType={mapType}
                        onOptionClick={updateMapType}
                    />
                    {verticalToggleOptions && verticalToggleOptions.length > 0 && verticalToggleIconUrl && (
                        <MapVerticalToggle
                            isOpen={isVerticalToggleOpen}
                            onToggle={handleToggle}
                            verticalToggleIconUrl={verticalToggleIconUrl}
                            verticalToggleOptions={verticalToggleOptions}
                        />
                    )}
                </div>
            </div>
        </>
    );
}
