import type { DynamicPageProps } from '@/core/features/a-dynamic-page/dynamic-page-types';

import React from 'react';

import debounce from 'lodash.debounce';

import ChevronRightSmallIcon from '@/assets/icons/chevron-right-small';
import { IS_SERVER } from '@/constants/env';
import NotificationWrapper from '@/core/components/notification/notification-wrapper';
import { ButtonWrapper, LoopedLoadingBar } from '@/core/features';
import { getComponentId } from '@/core/features/a-component/services/component-id-service';
import { useClientUrl } from '@/core/features/app/app-atoms';
import useClientOnlyRender from '@/core/features/render/use-client-only-render';
import {
    restorePageScrollPosition,
    savePageScrollPosition,
} from '@/core/features/scroll/page-scroll-restoration/scroll-history';
import { scrollToTop } from '@/core/features/scroll/scroll-service';
import useHasInteractedRef from '@/core/features/scroll/use-has-interacted-ref';
import { atom, useAtom } from '@/core/features/store/atom-store';
import color from '@/core/features/styles/color';
import { bemModule } from '@/core/utils/bem-classname';
import PageComponentSwitchWrapperDesktop from '@/features/a-dynamic-page/components/page-component-switch-wrapper-desktop';
import { useGoogleMapInstance } from '@/features/map/google-map/google-map-state';
import MapComponentDesktop from '@/features/map/map-container/map-component-desktop';
import { useMapLoadingState } from '@/features/map/map-container/map-state';
import DesktopContentWrapper from '@/features/page-layouts/desktop/desktop-content-wrapper';

import styles from './desktop-map-search-layout.module.scss';

const bem = bemModule(styles);
export const showMainContentAtom = atom(true);
export default function DesktopMapSearchLayout(props: DynamicPageProps) {
    const [showMainContent, setShowMainContent] = useAtom(showMainContentAtom);
    const shouldRenderMap = useClientOnlyRender();
    const isMapLoading = useMapLoadingState();
    const [clientUrl] = useClientUrl();
    const windowRef = React.useRef(IS_SERVER ? null : window);
    const hasInteractedRef = useHasInteractedRef(windowRef);

    //FIXME: dont use pageType
    const useLegacyMinWidth = props.meta?.pageType === 'activity';

    const onMapResize = () => {
        if (showMainContent) {
            savePageScrollPosition();
        }
        if (!showMainContent) {
            restorePageScrollPosition(clientUrl, { hasInteractedRef });
        }
        scrollToTop('instant');
        setShowMainContent(!showMainContent);
    };
    const googleMapInstace = useGoogleMapInstance();
    // handle browser back scrolling to top becuase map is initializing
    const isInitializing = React.useRef(false);
    React.useEffect(() => {
        isInitializing.current = true;
        setTimeout(() => {
            isInitializing.current = false;
        }, 2000);
    }, []);
    const onMapInteraction = React.useCallback(() => {
        clearScollToTopTimeout();
        if (isInitializing.current) {
            return;
        }
        scrollToTopTimeoutRef.current = setTimeout(() => {
            scrollToTop();
        }, 1000);
    }, []);
    /**
     * Effect: cleanup scroll to top timeout
     */
    const clearScollToTopTimeout = () => {
        if (scrollToTopTimeoutRef.current !== null) {
            clearTimeout(scrollToTopTimeoutRef.current);
        }
    };
    React.useEffect(() => {
        const scrollHandler = debounce(
            () => {
                clearScollToTopTimeout();
            },
            200,
            { leading: true },
        );
        window.addEventListener('scroll', scrollHandler);
        return () => {
            window.removeEventListener('scroll', scrollHandler);
            clearScollToTopTimeout();
        };
    }, []);
    const scrollToTopTimeoutRef = React.useRef<NodeJS.Timeout | null>(null);
    React.useEffect(() => {
        if (!googleMapInstace) {
            return;
        }
        const zoomChangeListener = googleMapInstace.addListener('zoom_changed', onMapInteraction);
        const dragendListener = googleMapInstace.addListener('dragend', onMapInteraction);
        return () => {
            google.maps.event.removeListener(zoomChangeListener);
            google.maps.event.removeListener(dragendListener);
        };
    }, [googleMapInstace, onMapInteraction]);
    const headerComponents = props.components.filter((components) => components.meta.layoutPosition === 'header');
    const asideComponents = props.components.filter((component) => component.meta.layoutPosition === 'aside');
    const mainContentComponents = props.components.filter(
        (component) => component.meta.layoutPosition === 'main_content',
    );
    const mapDataComponent = props.components.find((component) => component['@type'] === 'MapDataV5');
    return (
        <>
            <DesktopContentWrapper isPageLoading={props.isPageLoading}>
                <div className={'flex'}>
                    <div
                        className={`${bem(styles.mainLeft, { isMainContentHidden: !showMainContent, useLegacyMinWidth })}  flex-column gap-15 flex-1 width-100`}
                    >
                        {headerComponents.length > 0 && (
                            <div className={bem(styles.header, { isMainContentHidden: !showMainContent })}>
                                <div className={'flex-column width-100 gap-10'}>
                                    <PageComponentSwitchWrapperDesktop
                                        {...props}
                                        components={headerComponents}
                                    />
                                </div>
                            </div>
                        )}
                        <div className={'flex gap-15'}>
                            {asideComponents.length > 0 && (
                                <div
                                    className={`${bem(styles.mainContentAside, { isMainContentHidden: !showMainContent })} flex-column gap-10`}
                                >
                                    <PageComponentSwitchWrapperDesktop
                                        {...props}
                                        components={asideComponents}
                                    />
                                </div>
                            )}
                            {mainContentComponents.length > 0 && (
                                <div
                                    className={bem(styles.mainContentSpotResult, {
                                        isMainContentHidden: !showMainContent,
                                    })}
                                >
                                    <div className={'flex-column gap-15'}>
                                        <PageComponentSwitchWrapperDesktop
                                            {...props}
                                            components={mainContentComponents}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div
                        className={`${bem(styles.mainRight, { isMainContentHidden: !showMainContent })} flex-center height-100dvh`}
                    >
                        <ButtonWrapper
                            className={`${styles.toogleButton} flex-center border-radius pointer`}
                            height={40}
                            onClick={onMapResize}
                            width={40}
                        >
                            <span
                                className={'flex-center bg-wildSand'}
                                data-qa-id={'qa-map-split-toggle-button'}
                            >
                                <ChevronRightSmallIcon
                                    color={color('black-09')}
                                    height={15}
                                    rotate={showMainContent ? 180 : 0}
                                    width={15}
                                />
                            </span>
                        </ButtonWrapper>
                        <div className={'width-100'}>
                            {mapDataComponent && shouldRenderMap && (
                                <div className={'height-100 height-100dvh bg-wildSand overflow-hidden'}>
                                    {isMapLoading && (
                                        <LoopedLoadingBar className={'width-100 absolute top-0 left-10'} />
                                    )}
                                    <MapComponentDesktop componentId={getComponentId(mapDataComponent['@id'])} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </DesktopContentWrapper>
            <NotificationWrapper />
        </>
    );
}
