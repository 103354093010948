import type { MapType } from '@/features/map/map-components/map-layer-switch/map-layer-switch';

import React from 'react';

import { IconImage } from '@/core/features';
import color from '@/core/features/styles/color';
import { mapLayerOptions } from '@/features/map/map-components/map-layer-switch/map-layer-switch';

interface MapLayerSwitchProps {
    activeMapType: MapType;
    onOptionClick: (key: MapType) => void;
}

export default function MapLayerSwitchDesktop({ activeMapType, onOptionClick }: MapLayerSwitchProps) {
    const [activeMapTypeOption, setActiveMapOption] = React.useState(activeMapType);

    if (!activeMapTypeOption) {
        return null;
    }

    const toggleMapOption = () => {
        const nextMapOption = activeMapTypeOption === 'roadmap' ? 'hybrid' : 'roadmap';
        setActiveMapOption(nextMapOption);
        onOptionClick(nextMapOption);
    };

    const displayedLayerOptions =
        activeMapTypeOption === 'roadmap' ? mapLayerOptions['hybrid'] : mapLayerOptions['roadmap'];

    return (
        <div
            className={'border-radius-5 pointer'}
            data-qa-id={'qa-map-layer-switch-desktop'}
            style={{
                /* eslint-disable no-inline-styles/no-inline-styles */
                border: `3px solid ${color('white')}`,
            }}
        >
            <div className={'border-radius-3 overflow-hidden'}>
                <span onClick={toggleMapOption}>
                    <IconImage
                        alt={displayedLayerOptions.label}
                        height={40}
                        url={displayedLayerOptions.imageUrl}
                        width={40}
                    />
                </span>
            </div>
        </div>
    );
}
